import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box, CircularProgress } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import API, { endpoints } from '../../configs/API';

const COLORS = ['#1976d2', '#2e7d32', '#d32f2f', '#ed6c02', '#9c27b0', '#0288d1', '#f57c00', '#388e3c'];

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userStats, setUserStats] = useState({
    byCommune: [],
    byDistrict: [],
    byProvider: []
  });

  useEffect(() => {
    const fetchStats = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const [users, communes, districts, providers] = await Promise.all([
          API.get(endpoints['users']),
          API.get(endpoints['communes']),
          API.get(endpoints['districts']),
          API.get(endpoints['providers']),
        ]);

        const userData = users.data;

        const usersByCommune = {};
        userData.forEach(user => {
          const communeName = communes.data.find(c => c.CommuneID === user.CommuneID)?.CommuneName || 'Unknown';
          usersByCommune[communeName] = (usersByCommune[communeName] || 0) + 1;
        });

        const usersByDistrict = {};
        userData.forEach(user => {
          const commune = communes.data.find(c => c.CommuneID === user.CommuneID);
          const districtName = districts.data.find(d => d.DistrictID === commune?.DistrictID)?.DistrictName || 'Unknown';
          usersByDistrict[districtName] = (usersByDistrict[districtName] || 0) + 1;
        });

        const usersByProvider = {};
        userData.forEach(user => {
          const providerName = providers.data.find(p => p.ProviderID === user.ProviderID)?.ProviderName || 'Unknown';
          usersByProvider[providerName] = (usersByProvider[providerName] || 0) + 1;
        });

        setUserStats({
          byCommune: Object.entries(usersByCommune)
            .map(([name, value]) => ({ name, value }))
            .sort((a, b) => b.value - a.value),
          byDistrict: Object.entries(usersByDistrict)
            .map(([name, value]) => ({ name, value }))
            .sort((a, b) => b.value - a.value),
          byProvider: Object.entries(usersByProvider)
            .map(([name, value]) => ({ name, value }))
            .sort((a, b) => b.value - a.value)
        });

      } catch (error) {
        console.error('Error fetching statistics:', error);
        setError('Có lỗi xảy ra khi tải dữ liệu. Vui lòng thử lại sau.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper sx={{ p: 1, backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
          <Typography variant="body2">
            <strong>{payload[0].payload.name}</strong>
          </Typography>
          <Typography variant="body2">
            Số người dùng: {payload[0].value}
          </Typography>
        </Paper>
      );
    }
    return null;
  };

  const CustomPieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="12"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>Thống Kê Người Dùng</Typography>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Paper sx={{ p: 3, height: 400 }}>
            <Typography variant="h6" sx={{ }}>Theo Huyện</Typography>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={userStats.byDistrict}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={CustomPieLabel}
                  outerRadius={130}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {userStats.byDistrict.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Paper sx={{ p: 3, height: 400 }}>
            <Typography variant="h6" sx={{ }}>Theo Xã</Typography>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={userStats.byCommune}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 60, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={100} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="value" fill="#8884d8">
                  {userStats.byCommune.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Paper sx={{ p: 3, height: 400 }}>
            <Typography variant="h6" sx={{ }}>Theo Nhà Cung Cấp</Typography>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={userStats.byProvider}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={CustomPieLabel}
                  outerRadius={130}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {userStats.byProvider.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
