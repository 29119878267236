import axios from "axios";

const BASE_URL = "http://localhost:8000/api/";

export const endpoints = {
    'communes': 'communes',
    'commune-id': (id) => `communes/${id}`,
    'districts': 'districts',
    'district-id': (id) => `districts/${id}`,
    'users': 'users',
    'users-id': (id) => `users/${id}`,
    'providers': 'providers',
};

export default axios.create({
  baseURL: BASE_URL,
});
