import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import API, { endpoints } from '../../configs/API';

const Communes = () => {
  const [communes, setCommunes] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [formData, setFormData] = useState({
    CommuneName: '',
    DistrictID: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCommunes, setFilteredCommunes] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');

  useEffect(() => {
    fetchCommunes();
    fetchDistricts();
  }, []);

  const fetchCommunes = async () => {
    try {
      const response = await API.get(endpoints['communes']);
      setCommunes(response.data);
      setFilteredCommunes(response.data);
    } catch (error) {
      console.error('Error fetching communes:', error);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await API.get(endpoints['districts']);
      setDistricts(response.data);
    } catch (error) {
      console.error('Error fetching districts:', error);
    }
  };

  useEffect(() => {
    let filtered = communes;

    if (selectedDistrict) {
      filtered = filtered.filter((commune) => commune.DistrictID === selectedDistrict);
    }

    if (searchTerm.trim()) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter((commune) =>
        commune.CommuneName.toLowerCase().includes(searchLower) ||
        commune.DistrictName.toLowerCase().includes(searchLower)
      );
    }

    setFilteredCommunes(filtered);
  }, [communes, selectedDistrict, searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearFilter = () => {
    setSelectedDistrict('');
    setSearchTerm('');
  };

  const handleOpen = (item = null) => {
    if (item) {
      setFormData({
        CommuneName: item.CommuneName,
        DistrictID: item.DistrictID,
      });
      setEditItem(item);
    } else {
      setFormData({
        CommuneName: '',
        DistrictID: '',
      });
      setEditItem(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditItem(null);
    setFormData({
      CommuneName: '',
      DistrictID: '',
    });
  };

  const handleSubmit = async () => {
    try {
      if (editItem) {
        await API.put(endpoints['commune-id'](editItem.CommuneID), formData);
      } else {
        await API.post(endpoints['communes'], formData);
      }
      fetchCommunes();
      handleClose();
    } catch (error) {
      console.error('Error saving commune:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this commune?')) {
      try {
        await API.delete(endpoints['commune-id'](id));
        fetchCommunes();
      } catch (error) {
        console.error('Error deleting commune:', error);
      }
    }
  };

  return (
    <div>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Xã</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpen()}
        >
          Thêm Xã
        </Button>
      </Box>

      <Box sx={{ mb: 3, display: 'flex', gap: 1 }}>
        <TextField
          label="Tìm kiếm xã"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Nhập tên xã để tìm kiếm..."
          fullWidth
        />
      </Box>

      <Box sx={{ mb: 3, display: 'flex', gap: 1 }}>
        <FormControl
          fullWidth
          size="small"
        >
          <InputLabel>Huyện</InputLabel>
          <Select
            value={selectedDistrict}
            label="Huyện"
            onChange={(e) => {
              setSelectedDistrict(e.target.value);
            }}
          >
            <MenuItem value="">Tất cả</MenuItem>
            {districts.map((district) => (
              <MenuItem key={district.DistrictID} value={district.DistrictID}>
                {district.DistrictName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleClearFilter}
          disabled={!selectedDistrict && !searchTerm}
        >
          Hủy
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Tên</TableCell>
              <TableCell>Huyện</TableCell>
              <TableCell align="right">Số lượng người dùng</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCommunes.map((commune) => (
              <TableRow key={commune.CommuneID}>
                <TableCell>{commune.CommuneID}</TableCell>
                <TableCell>{commune.CommuneName}</TableCell>
                <TableCell>{commune.DistrictName}</TableCell>
                <TableCell align="right">{commune.UserCount}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleOpen(commune)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(commune.CommuneID)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editItem ? 'Cập Nhật Xã' : 'Thêm Xã'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Tên Xã"
            fullWidth
            value={formData.CommuneName}
            onChange={(e) => setFormData({ ...formData, CommuneName: e.target.value })}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Huyện</InputLabel>
            <Select
              value={formData.DistrictID}
              label="District"
              onChange={(e) => setFormData({ ...formData, DistrictID: e.target.value })}
            >
              {districts.map((district) => (
                <MenuItem key={district.DistrictID} value={district.DistrictID}>
                  {district.DistrictName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={handleSubmit} variant="contained">
            {editItem ? 'Cập Nhật' : 'Thêm'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Communes;
