import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import API, { endpoints } from '../../configs/API';

const Providers = () => {
  const [providers, setProviders] = useState([]);
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [formData, setFormData] = useState({
    ProviderName: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProviders, setFilteredProviders] = useState([]);

  useEffect(() => {
    fetchProviders();
  }, []);

  const fetchProviders = async () => {
    try {
      const response = await API.get(endpoints['providers']);
      setProviders(response.data);
      setFilteredProviders(response.data);
    } catch (error) {
      console.error('Error fetching providers:', error);
    }
  };

  // Update filtered providers when search term changes
  useEffect(() => {
    let filtered = providers;

    if (searchTerm.trim()) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter((provider) =>
        provider.ProviderName.toLowerCase().includes(searchLower)
      );
    }

    setFilteredProviders(filtered);
  }, [providers, searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearFilter = () => {
    setSearchTerm('');
  };

  const handleOpen = (item = null) => {
    if (item) {
      setFormData({ ProviderName: item.ProviderName });
      setEditItem(item);
    } else {
      setFormData({ ProviderName: '' });
      setEditItem(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditItem(null);
    setFormData({ ProviderName: '' });
  };

  const handleSubmit = async () => {
    try {
      if (editItem) {
        await API.put(endpoints['provider-id'](editItem.ProviderID), formData);
      } else {
        await API.post(endpoints['providers'], formData);
      }
      fetchProviders();
      handleClose();
    } catch (error) {
      console.error('Error saving provider:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this provider?')) {
      try {
        await API.delete(endpoints['provider-id'](id));
        fetchProviders();
      } catch (error) {
        console.error('Error deleting provider:', error);
      }
    }
  };

  return (
    <div>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Nhà Cung Cấp</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpen()}
        >
          Thêm Nhà Cung Cấp
        </Button>
      </Box>

      <Box sx={{ mb: 3, display: 'flex', gap: 1 }}>
        <TextField
          label="Tìm kiếm nhà cung cấp"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Nhập tên nhà cung cấp để tìm kiếm..."
          fullWidth
        />
        <Button
          variant="contained"
          onClick={handleClearFilter}
          disabled={!searchTerm}
        >
          Hủy
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Tên</TableCell>
              <TableCell align="right">Số lượng người dùng</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProviders.map((provider) => (
              <TableRow key={provider.ProviderID}>
                <TableCell>{provider.ProviderID}</TableCell>
                <TableCell>{provider.ProviderName}</TableCell>
                <TableCell align="right">{provider.UserCount}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleOpen(provider)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(provider.ProviderID)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editItem ? 'Cập Nhật Nhà Cung Cấp' : 'Thêm Nhà Cung Cấp'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Tên Nhà Cung Cấp"
            fullWidth
            value={formData.ProviderName}
            onChange={(e) => setFormData({ ...formData, ProviderName: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={handleSubmit} variant="contained">
            {editItem ? 'Cập Nhật' : 'Thêm'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Providers;
