import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import API, { endpoints } from '../../configs/API';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [providers, setProviders] = useState([]);
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [formData, setFormData] = useState({
    UserName: '',
    PhoneNumber: '',
    Address: '',
    CommuneID: '',
    ProviderID: '',
    DistrictID: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCommunes, setFilteredCommunes] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    CommuneID: '',
    DistrictID: '',
    ProviderID: ''
  });
  const [communesToFilter, setCommunesToFilter] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
    fetchDistricts();
    fetchCommunes();
    fetchProviders();
  }, []);

  useEffect(() => {
    if (formData.DistrictID) {
      setFilteredCommunes(communes.filter(commune => commune.DistrictID === formData.DistrictID));
    } else {
      setFilteredCommunes([]);
    }
  }, [formData.DistrictID, communes]);

  useEffect(() => {
    if (filterCondition.DistrictID) {
      setCommunesToFilter(communes.filter(commune => commune.DistrictID === filterCondition.DistrictID));
    } else {
      setCommunesToFilter([]);
    }
  }, [filterCondition.DistrictID, communes]);

  useEffect(() => {
    const filtered = users.filter(user => {
      const matchProvider = !filterCondition.ProviderID || user.ProviderID === filterCondition.ProviderID;
      const matchDistrict = !filterCondition.DistrictID || user.DistrictID === filterCondition.DistrictID;
      const matchCommune = !filterCondition.CommuneID || user.CommuneID === filterCondition.CommuneID;

      return matchProvider && matchDistrict && matchCommune;
    });

    if (searchTerm.trim()) {
      const searchLower = searchTerm.toLowerCase();
      const searchFiltered = filtered.filter(user =>
        user.UserName.toLowerCase().includes(searchLower) ||
        user.PhoneNumber.toLowerCase().includes(searchLower) ||
        user.Address.toLowerCase().includes(searchLower)
      );
      setFilteredUsers(searchFiltered);
    } else {
      setFilteredUsers(filtered);
    }
  }, [filterCondition, users, searchTerm]);


const handleClearFilter = () => {
  setFilterCondition({
    CommuneID: '',
    DistrictID: '',
    ProviderID: ''
  });
  setSearchTerm('');
};

  const fetchUsers = async () => {
    try {
      const response = await API.get(endpoints['users']);
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await API.get(endpoints['districts']);
      setDistricts(response.data);
    } catch (error) {
      console.error('Error fetching districts:', error);
    }
  };

  const fetchCommunes = async () => {
    try {
      const response = await API.get(endpoints['communes']);
      setCommunes(response.data);
    } catch (error) {
      console.error('Error fetching communes:', error);
    }
  };

  const fetchProviders = async () => {
    try {
      const response = await API.get(endpoints['providers']);
      setProviders(response.data);
    } catch (error) {
      console.error('Error fetching providers:', error);
    }
  };

  const handleSearch = async () => {
    setSearchTerm(searchTerm.trim());
  };

  const handleOpen = (item = null) => {
    if (item) {
      setFormData({
        UserName: item.UserName,
        PhoneNumber: item.PhoneNumber,
        Address: item.Address,
        CommuneID: item.CommuneID,
        ProviderID: item.ProviderID,
        DistrictID: item.DistrictID,
      });
      setEditItem(item);
    } else {
      setFormData({
        UserName: '',
        PhoneNumber: '',
        Address: '',
        CommuneID: '',
        ProviderID: '',
        DistrictID: '',
      });
      setEditItem(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditItem(null);
    setFormData({
      UserName: '',
      PhoneNumber: '',
      Address: '',
      CommuneID: '',
      ProviderID: '',
      DistrictID: '',
    });
  };

  const handleSubmit = async () => {
    try {
      if (editItem) {
        await API.put(endpoints['users-id'](editItem.UserID), formData);
      } else {
        await API.post(endpoints['users'], formData);
      }
      fetchUsers();
      handleClose();
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await API.delete(endpoints['users-id'](id));
        fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  return (
    <div>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Người Dùng</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpen()}
        >
          Thêm Người Dùng
        </Button>
      </Box>

      <Box sx={{ mb: 3, display: 'flex', gap: 1 }}>
        <TextField
          label="Tìm Kiếm Người Dùng"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          onKeyUp={(e) => e.key === 'Enter' && handleSearch()}
        />
        <Button variant="contained" onClick={handleSearch}>
          Tìm Kiếm
        </Button>
      </Box>
      <Box sx={{ mb: 3, display: 'flex', gap: 1 }}>
        <FormControl
          fullWidth
          size="small"
        >
          <InputLabel>Huyện</InputLabel>
          <Select
            value={filterCondition.DistrictID}
            label="Huyện"
            onChange={(e) => {
              setFilterCondition({
                ...filterCondition,
                DistrictID: e.target.value,
                CommuneID: ''
              });
            }}
          >
            <MenuItem value="">Tất cả</MenuItem>
            {districts.map((district) => (
              <MenuItem key={district.DistrictID} value={district.DistrictID}>
                {district.DistrictName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          size="small"
        >
          <InputLabel>Xã</InputLabel>
          <Select
            value={filterCondition.CommuneID}
            label="Xã"
            onChange={(e) => setFilterCondition({ ...filterCondition, CommuneID: e.target.value })}
            disabled={!filterCondition.DistrictID}
          >
            <MenuItem value="">Tất cả</MenuItem>
            {communesToFilter.map((commune) => (
              <MenuItem key={commune.CommuneID} value={commune.CommuneID}>
                {commune.CommuneName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          size="small"
        >
          <InputLabel>Nhà Cung Cấp</InputLabel>
          <Select
            value={filterCondition.ProviderID}
            label="Nhà Cung Cấp"
            onChange={(e) => setFilterCondition({ ...filterCondition, ProviderID: e.target.value })}
          >
            <MenuItem value="">Tất cả</MenuItem>
            {providers.map((provider) => (
              <MenuItem key={provider.ProviderID} value={provider.ProviderID}>
                {provider.ProviderName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleClearFilter}>
          Hủy
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Họ Và Tên</TableCell>
              <TableCell>Số Điện Thoại</TableCell>
              <TableCell>Địa Chỉ</TableCell>
              <TableCell>Huyện</TableCell>
              <TableCell>Xã</TableCell>
              <TableCell>Nhà Cung Cấp</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.UserID}>
                <TableCell>{user.UserID}</TableCell>
                <TableCell>{user.UserName}</TableCell>
                <TableCell>{user.PhoneNumber}</TableCell>
                <TableCell>{user.Address}</TableCell>
                <TableCell>{user.DistrictName}</TableCell>
                <TableCell>{user.CommuneName}</TableCell>
                <TableCell>{user.ProviderName}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleOpen(user)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(user.UserID)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="md"
          fullWidth
          size="small"
        >
        <DialogTitle>{editItem ? 'Cập Nhật Người Dùng' : 'Thêm Người Dùng'}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)', mt: 2 }}>
            <TextField
              label="Họ Và Tên"

          fullWidth
          size="small"

              value={formData.UserName}
              onChange={(e) => setFormData({ ...formData, UserName: e.target.value })}
            />
            <TextField
              label="Số Điện Thoại"

          fullWidth
          size="small"

              value={formData.PhoneNumber}
              onChange={(e) => setFormData({ ...formData, PhoneNumber: e.target.value })}
            />
            <TextField
              label="Địa Chỉ"

          fullWidth
          size="small"

              value={formData.Address}
              onChange={(e) => setFormData({ ...formData, Address: e.target.value })}
            />
            <FormControl
          fullWidth
          size="small"
        >
              <InputLabel>Nhà Cung Cấp</InputLabel>
              <Select
                value={formData.ProviderID}
                label="Nhà Cung Cấp"
                onChange={(e) => setFormData({ ...formData, ProviderID: e.target.value })}
              >
                {providers.map((provider) => (
                  <MenuItem key={provider.ProviderID} value={provider.ProviderID}>
                    {provider.ProviderName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
          fullWidth
          size="small"
        >
              <InputLabel>Huyện</InputLabel>
              <Select
                value={formData.DistrictID}
                label="Huyện"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    DistrictID: e.target.value,
                    CommuneID: ''
                  });
                }}
              >
                {districts.map((district) => (
                  <MenuItem key={district.DistrictID} value={district.DistrictID}>
                    {district.DistrictName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
          fullWidth
          size="small"
        >
              <InputLabel>Xã</InputLabel>
              <Select
                value={formData.CommuneID}
                label="Xã"
                onChange={(e) => setFormData({ ...formData, CommuneID: e.target.value })}
                disabled={!formData.DistrictID}
              >
                {filteredCommunes.map((commune) => (
                  <MenuItem key={commune.CommuneID} value={commune.CommuneID}>
                    {commune.CommuneName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={handleSubmit} variant="contained">
            {editItem ? 'Cập Nhật' : 'Thêm'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Users;
